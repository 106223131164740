import React from 'react'
import Nyeste from '../pages/Nyeste'

const AdminHome = () => {
    return (
        <div>

            <Nyeste/>

        </div>
    )
}

export default AdminHome
